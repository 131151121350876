<template>
  <div class="auth-form-wrapper">
    <div class="auth-form-container">
      <div class="auth-form">
        <ShowTitle text="修改密碼" type="title" />

        <i
          @click="$router.push({ path: '/user/profile' })"
          class="
            auth-close-btn
            pointer
            el-icon-close
            basic-hover
            ease-in-out-03s
            font-gray
          "
        ></i>
        <el-form>
          <el-form-item>
            <el-input
              :type="newPassword"
              style="width: 260px"
              placeholder="新密碼"
              v-model="dataObj.newPassword"
            >
              <span
                slot="suffix"
                @click="
                  newPassword = newPassword === 'password' ? '' : 'password'
                "
              >
                <i
                  class="el-icon"
                  :class="newPassword ? 'el-icon-lock' : 'el-icon-unlock'"
                />
              </span>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              :type="confirmNewPassword"
              style="width: 260px"
              placeholder="確認新密碼"
              v-model="dataObj.confirmNewPassword"
            >
              <span
                slot="suffix"
                @click="
                  confirmNewPassword =
                    confirmNewPassword === 'password' ? '' : 'password'
                "
              >
                <i
                  class="el-icon"
                  :class="
                    confirmNewPassword ? 'el-icon-lock' : 'el-icon-unlock'
                  "
                />
              </span>
            </el-input>
          </el-form-item>
        </el-form>
        <div class="form-button-container">
          <el-button
            class="ease-in-out-03s basic-submit-btn"
            :loading="submitLoading"
            @click="HandleSubmit()"
          >
            修改密碼
          </el-button>
          <div class="margin-top-sm"></div>
          <a
            class="ease-in-out-03s basic-hover font-blue pointer"
            @click="$router.push({ path: '/user/profile' })"
            >取消
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainCommon from "@/utils/mainCommon";
import { userPasswordChange } from "@/api/user";
import { userProfile } from "@/api/user";

export default {
  mixins: [mainCommon],
  data() {
    return {
      submitLoading: false,
      newPassword: "password",
      confirmNewPassword: "password",
      dataObj: {},
    };
  },
  created() {
    this.getDataObj();
  },
  methods: {
    HandleSubmit() {
      this.submitLoading = true;
      userPasswordChange(this.dataObj)
        .then((res) => {
          this.pushNotification(res.message);
          this.HandleSignOut();
          setTimeout(() => {
            location.href = "/user/signin";
          }, 1000);
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    getDataObj() {
      this.submitLoading = true;
      userProfile().then((res) => {
        this.submitLoading = false;
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-input .el-input__inner {
  padding: 0 15px;
}
</style>